//This will be a file used to handle drop downs for certain links in district information / communittees
document.addEventListener("turbolinks:load", () => {
    //Determines filter by ASC or DESC
    //Will be optimized
    var asc_desc = "ASC";

    //Gets type of document
    var currentType = document.getElementById("currentType");

    //Gets year for default load
    var dropdownButton = document.getElementById("dropdownchooser");

    //Handles default year on load
    $.ajax({
        url: "/web/district/committee_table",type: "get",data: {Year: dropdownButton.innerHTML, Doc: currentType.value, Filter: 'meeting_date', order: asc_desc},success: function(data) {},error: function(data) {}
    });

    //Handles picking years on the page
    var docDrop = document.getElementsByClassName("docDrop");
    for (let i = 0; i < docDrop.length; i++) {
        docDrop[i].addEventListener('click', () => {
            $.ajax({
                url: "/web/district/committee_table",
                type: "get",
                data: {Year: docDrop[i].innerHTML, Doc: currentType.value, Filter: 'meeting_date', order: asc_desc},
                success: function(data) {},
                error: function(data) {}
            });
        });
    };

    //Handles filtering by title and location
    var filters = document.getElementsByClassName('filterer');
    for (let i = 0; i < filters.length; i++) {
        filters[i].addEventListener('click', () => {
            $.ajax({
                url: "/web/district/committee_table",
                type: "get",
                data: {Year: dropdownButton.innerHTML, Doc: currentType.value, Filter: filters[i].innerHTML, order: asc_desc == "ASC" ? "DESC" : "ASC"},
                success: function(data) {asc_desc = asc_desc == "ASC" ? "DESC" : "ASC"},
                error: function(data) {}
            });
        });
    }
}); 
