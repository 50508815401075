
var mceAPString = '<p style="text-align: center;"><strong>Instructor:</strong>&nbsp;teacher</p> <p style="text-align: center;"><strong>Grade Level:</strong>&nbsp;grade</p> <p style="text-align: center;">description</p>';
var mceBox;

// ?? select_visibility_toggle_controller.js ??
document.addEventListener("turbolinks:load", () => {
    $('#content_section_location').on('change', function(){
        var test = $(this).val();
        if(test == "Polymorphic Relationship"){
            $(".polyHide").show();
        }
        else{
            $(".polyHide").hide();
        }
    });
});

document.addEventListener("turbolinks:load", () => {
    var e = document.getElementById("academic_course_ap");
    if ( e != null)
    {
        document.getElementById("academic_course_ap").addEventListener('change', function() {
            if (document.getElementById("academic_course_ap").checked)
            {
                mceBox = tinymce.get("academic_course_description").getContent();
                tinymce.get("academic_course_description").setContent(mceAPString);
            }
            else
            {
                mceAPString = tinymce.get("academic_course_description").getContent();
                tinymce.get("academic_course_description").setContent(mceBox);
            }
        });
    }
});

document.addEventListener("turbolinks:load", () => {
    if (document.getElementById("removeHeader") != null){
        document.getElementsByClassName("early_college_banner")[0].style.display = "none";
    }
});


document.addEventListener("turbolinks:load", () => {
  $(".image-delete").click(function(event) {
    $.ajax({
      url: "/web/academics/destroy_image",
      type: "GET",
      data: { slide_index_id:$(this).attr("name"), department_id:$(this).attr("id") },
      success: function(data) {
            if (confirm("Are you sure, want to delete?")) {
                location.reload();
                }
            },
      error: function(data) {}  
    })
  })
})