require("moment")
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
function eventCalendar() {
    var calendarEl = document.getElementById('calendar');
  
    var calendar = new Calendar(calendarEl, {
      events: '/web/auth/athletic/events.json',
      plugins: [ dayGridPlugin, interactionPlugin ],
      editable: true,
      displayEventTime: false,
      eventDisplay: 'block',
      eventColor: '#58489e',
      eventClassNames: 'py-2 link-underline link-underline-opacity-0 fs-5 ps-2 link-underline-opacity-100-hover text-decoration-underline link-light',
      eventContent: function(arg) {
        return { html: '<a href="/web/auth/content/events/' + arg.event.extendedProps.id + '/edit" class="text-white">' + arg.event.title + '</a>' }
      },
      eventDrop: function(info) {
        $.ajax({
          url: "/web/auth/athletic/events/drop",
          type: "POST",
          data: { event_id: info.event.extendedProps.id, start: info.event.startStr, authenticity_token: $('[name="csrf-token"]')[0].content},
          success: function(data) { console.log("Success")},
          error: function(data) { info.revert();}
        })
      }
    });
    calendar.render();
  };
document.addEventListener("turbolinks:load", () => {
  eventCalendar();
  $(".athletic-cal-item").click(function(event){
    $.ajax({
      url: "/web/athletics/set_modal",
      type: "GET",
      data: { event_id: $(this).attr("value") },
      success: function(data){},
      error: function(data){}
    })
  })
  

  $(".image-delete").click(function(event) {
    $.ajax({
      url: "/web/athletics/destroy_image",
      type: "GET",
      data: { slide_index_id:$(this).attr("name"), sport_id:$(this).attr("id") },
      success: function(data) {
            if (confirm("Are you sure, want to delete?")) {
                location.reload();
                }
            },
      error: function(data) {console.log("Error")}  
    })
  }) 

  
})