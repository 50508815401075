

// Loading only a single modal on calendar page
document.addEventListener("turbolinks:load", () => {
    $(".modalOpenBtn").click(function(){
        $.ajax({
            url: "/web/events/set_modal",
            type: "get",
            data: { event_id: $(this).attr("value") },
            success: function(data) {},
            error: function(data) {}
        });
    });
});