document.addEventListener("turbolinks:load", () => {
  $(".image-delete").click(function(event) {
    $.ajax({
      url: "/web/clubs/destroy_image",
      type: "GET",
      data: { slide_index_id:$(this).attr("name"), club_id:$(this).attr("id") },
      success: function(data) {
            if (confirm("Are you sure, want to delete?")) {
                location.reload();
                }
            },
      error: function(data) {console.log("Error")}  
    })
  })
})