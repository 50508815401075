
// pass namespace & model as data-attributes and build a single request
// for all of these
document.addEventListener("turbolinks:load", () => {
    $('#content_announcement_announcer_type').on('change', function() {
        $.ajax({
            url: "/web/auth/content/announcements/record_select_options",
            type: "get",
            data:
                {"model_name" : $('.remote-select').val()},
            success: function(data) {},
            error: function(data) {}
        })
    });
    $('#content_event_e_coordinator_type').on('change', function() {
        $.ajax({
            url: "/web/auth/content/events/record_select_options",
            type: "get",
            data:
                {"model_name" : $('.remote-select').val()},
            success: function(data) {},
            error: function(data) {}
        })
    });
    $('#staff_position_employable_type').on('change', function() {
        $.ajax({
            url: "/web/auth/staff/positions/record_select_options",
            type: "get",
            data:
                {"type" : $('.remote-select').val()},
            success: function(data) {},
            error: function(data) {}
        })
    });
    $('#content_section_content_owner_type').on('change', function() {
        $.ajax({
            url: "/web/auth/content/sections/record_select_options",
            type: "get",
            data:
                {"model_name" : $('.remote-select').val()},
            success: function(data) {},
            error: function(data) {}
        })
    });
    
  });