
// ?? select_visibility_toggle_controller.js ??
document.addEventListener("turbolinks:load", () => {
    toggleDropdownVisibility();
    $("#content_announcement_announcer_type").change (function(){
        toggleDropdownVisibility();
    });
});

function toggleDropdownVisibility(){
    if($("#content_announcement_announcer_type").val() == "Schoolwide"){
        $("#content_announcement_announcer_id").html(
            "<option value=''>Please select</option>"
        )
        $("#announcerForm").addClass("d-none").removeClass("d-block");
    }
    else{
        $("#announcerForm").removeClass("d-none").addClass("d-block");
    }
}
