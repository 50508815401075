
// ?? select_visibility_toggle_controller.js ??
document.addEventListener("turbolinks:load", () => {
    
    var s = document.getElementById("content_page_category");
    if(s != null) {
        var dropVal = s.options[s.selectedIndex].text;
        setVisibility(dropVal);
    }


    $('#content_page_category').change(function(){
        var dropdownVal = $(this).val();
        setVisibility(dropdownVal);
    });
});

function setVisibility(item){

    if (item == "External URL" || item == "Internal URL"){
        $('.enterLink').show();
        $('.pdfUpload').hide();
        $('.textEditor').hide();
    }
    if(item == "Menu Divider") {
        $('.pdfUpload').hide();
        $('.enterLink').hide();
        $('.textEditor').hide();
    }
    if (item == "PDF"){
        $('.pdfUpload').show();
        $('.enterLink').hide();
        $('.textEditor').hide();
    }
    if (item == "Basic Page"){
        $('.textEditor').show();
        $('.enterLink').hide();
        $('.pdfUpload').hide();
    }
    else{
        $('.allHide').hide();
    }
}